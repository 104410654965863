import React from 'react';

import css from '../styles.module.scss';

function AreaMarker(props) {
	const { label, onClick, count } = props;

	const countEl = count
		? (
			<div className={css.iconCount}>
	      <span>{count}</span>
	    </div>
		)
		: null;

	return (
		<div onClick={onClick} className={css.marker}>
			<span className={css.label}>{label}</span>
			{countEl}
		</div>
	);
}

export default AreaMarker;
