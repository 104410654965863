import React, { useState } from 'react';

// import HeaderGraph from './HeaderGraph';
// import HeaderStats from './HeaderStats';
import HeaderPerformance from './HeaderPerformance';
import OptionsModal from './optionsModal';

import css from './styles.module.scss';

function Header(props) {
  const { location, data, refetch } = props;

  const [showOptions, setShowOptions] = useState(false);

  const totalHead = data?.reduce((sum, area) => sum + area.total_cattle, 0);

  return (
    <>
      <div
        className={css.container}
        style={{ gap: 25 }}
      >
        <div className={css.title}>
          <h1>{location?.name}</h1>
          <span className={css.totalHead}>Total Head: {totalHead?.toLocaleString()}</span>
        </div>
        <div className={`${css.headerInfo} ${css.map}`}>
          {/*<HeaderStats {...props} />
          <HeaderGraph {...props} />*/}
          <HeaderPerformance {...props} setShowOptions={setShowOptions} />
        </div>
      </div>

      {/*<OptionsModal
        showModal={showOptions}
        setShowModal={setShowOptions}
        refetch={refetch}
        locationId={location.id}
      />*/}
    </>
  );
}

export default Header;
