import React from 'react';
import { Formik, Form } from 'formik';
import { useSearchParams } from 'react-router-dom';

import useMutation from '../../hooks/useMutation';
import useQuery from '../../hooks/useQuery';

import Client from '../../apis/Client';

import CustomInput from '../../components/forms/CustomInput';
import Loader from '../../components/Loader';
import ErrorBox from '../../components/ErrorBox';

import { required } from '../../validation/form';

import { Button } from 'react-bootstrap';
import css from '../AccountPage/AccountPage.module.scss';

const validate = (values) => {
    const errors = {};

    required(errors, values, 'lossDayStreak');
    required(errors, values, 'lossWeightChange');
    required(errors, values, 'gainDayStreak');
    required(errors, values, 'gainWeightChange');
    // required(errors, values, 'lowIntakeDaysBack');
    required(errors, values, 'lowIntakeHours');
    // required(errors, values, 'highIntakeDaysBack');
    required(errors, values, 'highIntakeHours');

    return errors;
};

function SettingsPage() {
  const [params] = useSearchParams();

  const locationId = params.get('locationId');

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['get_dashboard_options', locationId],
    queryFn: () => Client.get(`/api/v1/producers/locations/${locationId}/dashboard-options`)
  });

  const { mutate: updateSettings, isLoading: isSubmitLoading, error } = useMutation({
    mutationKey: 'post_dashboard_options',
    mutationFn: (data) => {
      const url = `/api/v1/producers/locations/${locationId}/dashboard-options`;
      return Client.put(url, data);
    },
    onSuccess: (data) => {
      refetch();
    },
  });

  const onSubmit = async (values, { resetForm }) => {
    await updateSettings(values);
    resetForm();
  }

  const initialValues = {
    lossDayStreak: data?.loss_day_streak || '',
    lossWeightChange: data?.loss_weight_change || '',
    gainDayStreak: data?.gain_day_streak || '',
    gainWeightChange: data?.gain_weight_change || '',
    // lowIntakeDaysBack: data?.low_intake_days_back || '',
    lowIntakeHours: data?.low_intake_hours || '',
    // highIntakeDaysBack: data?.high_intake_days_back || '',
    highIntakeHours: data?.high_intake_hours || ''
  };

  return (
    <>
      <h1>Settings</h1>

      <div className={css.acct}>
        <Loader loading={isLoading}>
          <Formik
            validate={validate}
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <div className={css.block}>
                  <div className={css.items}>
                    <CustomInput
                      label="Weight Loss Days"
                      name="lossDayStreak"
                      formik={formik}
                      required={true}
                      number
                      min={0}
                    />

                    <CustomInput
                      label="Per Day Loss Change"
                      name="lossWeightChange"
                      formik={formik}
                      required={true}
                      number
                      min={0}
                    />

                    <CustomInput
                      label="Weight Gain Days"
                      name="gainDayStreak"
                      formik={formik}
                      required={true}
                      number
                      min={0}
                    />

                    <CustomInput
                      label="Per Day Gain Change"
                      name="gainWeightChange"
                      formik={formik}
                      required={true}
                      number
                      min={0}
                    />
                  </div>
                </div>

                <div className={css.block}>
                  <div className={css.items}>
                    {/*<CustomInput
                      label="Low Intake Days"
                      name="lowIntakeDaysBack"
                      formik={formik}
                      required={true}
                      number
                      min={0}
                    />*/}

                    <CustomInput
                      label="Low Intake Hours"
                      name="lowIntakeHours"
                      formik={formik}
                      required={true}
                      number
                      min={0}
                      max={formik.values.highIntakeHours}
                    />

                    {/*<CustomInput
                      label="High Intake Days"
                      name="highIntakeDaysBack"
                      formik={formik}
                      required={true}
                      number
                      min={0}
                    />*/}

                    <CustomInput
                      label="High Intake Hours"
                      name="highIntakeHours"
                      formik={formik}
                      required={true}
                      number
                      min={formik.values.lowIntakeHours}
                      max={100}
                    />
                  </div>
                </div>

                <ErrorBox show={!!error} message={error?.message} />

                <div className={css.buttonBar}>
                  <Button
                    type="submit"
                    variant='btn btn-primary'
                    disabled={!Object.values(formik.touched).length || isSubmitLoading}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Loader>
      </div>
    </>
  );
}

export default SettingsPage;
