import React, { useState, useContext, useEffect } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';

import CameraStackContext from '../context/CameraStackContext';
import LocationContext from '../context/LocationContext';

const STACK_LIMIT = 5;

const CameraStackLayout = () => {
  const { locationId } = useContext(LocationContext);
  const [searchParams] = useSearchParams();
  const monitoringAreaId = searchParams.get('monitoringAreaId');
  const deviceId = searchParams.get('deviceId');

  const navigate = useNavigate();

  const [stack, setStack] = useState([]);

  useEffect(() => {
    if (locationId) {
      let lastVisited = localStorage.getItem('last_visited');
      lastVisited = JSON.parse(lastVisited) || {};
      if (!lastVisited || Array.isArray(lastVisited)) {
        lastVisited = {};
        localStorage.setItem('last_visited', JSON.stringify(lastVisited));
      } else {
        // clear last visited if data is outdated
        Object.values(lastVisited).forEach((arr) => {
          if (arr.some(tab => tab.deviceId === undefined)) {
            lastVisited = {};
            localStorage.setItem('last_visited', JSON.stringify(lastVisited));
          }
        });
      }

      const savedStack = lastVisited[locationId] || [];

      setStack(savedStack);
    }
  }, [locationId, navigate]);

  function addToStack(id, dId, name) {
    let copy = [...stack];
    const existingIndex = stack.findIndex(d => d.id === id && d.deviceId === dId);
    if (existingIndex > -1) {
      copy = [...stack];
    } else {
      copy = [{ id, deviceId: dId, name }, ...stack];
    }

    copy = copy.slice(0, STACK_LIMIT);

    let lastVisited = localStorage.getItem('last_visited')
    lastVisited = JSON.parse(lastVisited) || {};
    lastVisited = {
      ...lastVisited,
      [locationId]: copy
    };

    localStorage.setItem('last_visited', JSON.stringify(lastVisited));
    setStack(copy);
  }

  function removeFromStack(id, dId) {
    let copy = [...stack];
    const existingIndex = stack.findIndex(d => d.id === id && d.deviceId === dId);
    if (existingIndex > -1) {
      copy.splice(existingIndex, 1);
    }

    let lastVisited = localStorage.getItem('last_visited')
    lastVisited = JSON.parse(lastVisited) || {};
    lastVisited = {
      ...lastVisited,
      [locationId]: copy
    };

    localStorage.setItem('last_visited', JSON.stringify(lastVisited));
    setStack(copy);

    return {
      index: existingIndex,
      stack: copy,
      isOpen: id === monitoringAreaId || dId === deviceId
    };
  }

  function closeTab(id, dId) {
    const { index, stack: copy, isOpen } = removeFromStack(id, dId);

    if(!isOpen){
      //don't want to redirect if closing a tab that isn't active
      return
    }

    if (copy.length > 0) {
      const nextIndex = index > copy.length - 1
        ? copy.length - 1
        : index;

      const nextTab = copy[nextIndex];

      let nextUrl = `/producers/herd-sense?locationId=${locationId}&monitoringAreaId=${nextTab.id}`;
      if (nextTab.deviceId) {
        nextUrl += `&deviceId=${nextTab.deviceId}`;
      }

      navigate(nextUrl);
    } else {
      navigate(`/producers?locationId=${locationId}`);
    }
  }

  function closeAllTabs() {
    localStorage.setItem('last_visited', JSON.stringify({}));
    setStack([]);
    navigate(`/producers`);
  }

  return (
    <CameraStackContext.Provider
      value={{
        stack,
        addToStack,
        removeFromStack,
        closeTab,
        closeAllTabs
      }}
    >
      <Outlet />
    </CameraStackContext.Provider>
  );
};

export default CameraStackLayout;
