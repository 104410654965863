import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import PageHeader from './Header';
import NoteTable from './NoteTable';
import MapView from './MapView';

import css from './styles.module.scss';

import useQuery from '../../hooks/useQuery';

import Loader from '../../components/Loader';
import Client from '../../apis/Client';

function Dashboard() {
  const [params] = useSearchParams();

  const locationId = params.get('locationId');

  const [performance, setPerformance] = useState([]);

  const { data: location, isLoading: isLocationLoading } = useQuery({
    queryKey: ['get_producer_location', locationId],
    queryFn: () => Client.get(`/api/v1/producers/locations/${locationId}`),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });

  const { data, isLoading, isFetching, error, refetch } = useQuery({
    queryKey: ['get_location_poles', locationId],
    queryFn: () => Client.get(`/api/v1/producers/locations/${locationId}/monitoring-areas`),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });

  let noDataMsg = null;
  if (!isLoading && !data?.length) {
    noDataMsg = <span className={css.noPoles}>Sorry, there are no poles found at current location.</span>;
  }

  return (
    <>
      <Loader loading={isLoading || isLocationLoading}>
        <PageHeader
          location={location}
          data={data}
          performance={performance}
          setPerformance={setPerformance}
          refetch={refetch}
        />
      </Loader>

      <div className={css.content}>
        {noDataMsg}

        <Loader loading={isLoading}>
          <MapView
            locationId={locationId}
            data={data}
            isFetching={isFetching}
            performance={performance}
          />

          {/*<NoteTable
            locationId={locationId}
          />*/}
        </Loader>
      </div>
    </>
  );
}

export default Dashboard;
