import { useState } from 'react';

function useShowIds() {
  let settings = localStorage.getItem('settings');
  settings = JSON.parse(settings) || { showIds: true };

  const [showIds, setShowIds] = useState(settings.showIds);

  function updateShowIds(bool) {
    settings.showIds = bool;
    localStorage.setItem('settings', JSON.stringify(settings));
    setShowIds(bool);
  }

  return [showIds, updateShowIds];
}

export default useShowIds;
