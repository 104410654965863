import React from 'react';
import {
  Route
} from 'react-router-dom';

import ProtectedLayout from '../layouts/ProtectedLayout';
import ProducerLayout from '../layouts/ProducerLayout';
import LocationLayout from '../layouts/LocationLayout';
import LocationRequiredLayout from '../layouts/LocationRequiredLayout';
import EulaRequired from '../layouts/EulaRequired';
import CameraStackLayout from '../layouts/CameraStackLayout';

import PenManager from '../Producer/PenManager';
// import MobilePenStream from '../Producer/MobilePenStream';
import Dashboard from '../Producer/Dashboard';
import ProducerPageNotFound from '../Producer/ProducerPageNotFound';
import UsersPage from '../Producer/UsersPage';
import AccountPage from '../Producer/AccountPage';
// import VetChat from '../Producer/VetChat';
import SettingsPage from '../Producer/SettingsPage';

import { PRODUCER_PATH } from '../constants';

const ProducerRouter = (
  <Route path="">
    <Route path="/producers">
      <Route element={<ProtectedLayout portalDefaultPath={PRODUCER_PATH} />}>
        <Route element={<EulaRequired />}>
          <Route element={<LocationLayout />}>
            <Route element={<CameraStackLayout />}>
              <Route element={<ProducerLayout />}>
                <Route path="" element={<LocationRequiredLayout url="/api/v1/producers/locations" />}>
                  <Route path="" element={<Dashboard></Dashboard>} />
                  <Route path="dashboard" element={<Dashboard />}></Route>
                  <Route path="users" element={<UsersPage />} />
                  <Route path="herd-sense" element={<PenManager />} />
                  <Route path="settings" element={<SettingsPage />} />
                  {/*<Route path="vet-chat" element={<VetChat />} />*/}
                </Route>
                <Route path="account" element={<AccountPage />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      <Route
        path="*"
        element={<ProducerPageNotFound />}
      />
    </Route>
    {/*<Route path="/mobile/producers">
      <Route element={<ProtectedLayout portalDefaultPath={PRODUCER_PATH} />}>
        <Route path="herd-sense" element={<MobilePenStream />} />
      </Route>
    </Route>*/}
  </Route>
);

export default ProducerRouter;
