import React from 'react';

import Loader from '../../../components/Loader';
import ErrorBox from '../../../components/ErrorBox';

import HeaderCheckbox from './HeaderCheckbox';

import css from './styles.module.scss';

function HeaderPerformance(props) {
  const {
    data,
    isLoading,
    isError,
    error,
    performance,
    setPerformance,
    setShowOptions
  } = props;

  const lowIntake = data.reduce((s, a) => s + a.low_intake, 0);
  const highIntake = data.reduce((s, a) => s + a.high_intake, 0);
  const weightLosses = data.reduce((s, a) => s + a.weight_losses, 0);
  const weightGains = data.reduce((s, a) => s + a.weight_gains, 0);

  const handleCheck = (name) => {
    return (v) => {
      const copy = [...performance];
      const index = copy.findIndex(v => v === name);

      if (v && index < 0) {
        copy.push(name);
      } else if (!v && index >= 0) {
        copy.splice(index, 1);
      }

      setPerformance(copy);
    };
  }

  return isError
    ? <ErrorBox show={isError} message={error.message} />
    : (
      <Loader loading={isLoading}>
        <div className={css.performanceHeader}>
          <div className={`${css.category}`}>
            <h3>Lameness</h3>
            <div className={css.inputs}>
              <HeaderCheckbox
                label="Category 1"
                name="category_1"
                val={0}
                checked={performance.includes('category_1')}
                onCheck={handleCheck('category_1')}
              />
              <HeaderCheckbox
                label="Category 2"
                name="category_2"
                val={0}
                checked={performance.includes('category_2')}
                onCheck={handleCheck('category_2')}
              />
              <HeaderCheckbox
                label="Category 3"
                name="category_3"
                val={0}
                checked={performance.includes('category_3')}
                onCheck={handleCheck('category_3')}
              />
            </div>
          </div>
          <div className={`${css.category}`}>
            <h3>Performance</h3>
            <div className={css.inputs}>
              <HeaderCheckbox
                label="Poor Gain"
                name="weight_losses"
                val={weightLosses}
                checked={performance.includes('weight_losses')}
                onCheck={handleCheck('weight_losses')}
              />
              <HeaderCheckbox
                label="Great Gain"
                name="weight_gains"
                val={weightGains}
                checked={performance.includes('weight_gains')}
                onCheck={handleCheck('weight_gains')}
              />
              <HeaderCheckbox
                label="Low Intake"
                name="low_intake"
                val={lowIntake}
                checked={performance.includes('low_intake')}
                onCheck={handleCheck('low_intake')}
              />
              <HeaderCheckbox
                label="High Intake"
                name="high_intake"
                val={highIntake}
                checked={performance.includes('high_intake')}
                onCheck={handleCheck('high_intake')}
              />
            </div>
          </div>
        </div>
      </Loader>
    );
}

export default HeaderPerformance;
