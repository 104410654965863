class EventHandler {
  constructor(streamer) {
    this.streamer = streamer;
    this.onHover = () => {};
    this.onLoadingChange = () => {};

    this.zoom = this.minZoom;
    this.offsetX = 0;
    this.offsetY = 0;
    this.transform(this.zoom, this.offsetX, this.offsetY);

    this.mousePos = null;
    this.mouseDown = false;
    this.mouseMove = false;
    this.cattleBoxes = [];

    this.listeners = [
      { event: 'mousedown', fn: this.handleMouseDown.bind(this) },
      { event: 'mousemove', fn: this.handleMouseMove.bind(this) },
      { event: 'keydown', fn: this.onKeyDown.bind(this) },
      { event: 'mouseup', fn: this.handleMouseUp.bind(this) }
    ];
  }

  get minZoom() {
    const {
      clientWidth: containerWidth,
      clientHeight: containerHeight
    } = this.streamer.container;

    const {
      clientWidth,
      clientHeight
    } = this.streamer.videoSrc;

    return this.streamer.coverStyle === 'contain'
      ? 1
      : Math.max(containerWidth / clientWidth, containerHeight / clientHeight);
  }

  findHitBox(e) {
    const boundingRect =  this.streamer.canvas.getBoundingClientRect();

    const clientX = e.clientX - boundingRect.x;
    const clientY = e.clientY - boundingRect.y;

    for (let box of this.cattleBoxes) {
      const hit = box.x1 <= clientX
        && box.x2 > clientX
        && box.y1 <= clientY
        && box.y2 > clientY
        && box.gId;

      if (hit) {
        return box;
      }
    }
  }

  registerClickBoxes(payload, frameCoordToCanvas) {
    if (!payload) {
      return;
    }

    const arr = [];

    for (const pose of payload.instances) {
      const {bbox, gId} = pose;

      if (bbox) {
        let start_x = bbox[0];
        let start_y = bbox[1];
        let end_x = bbox[2];
        let end_y = bbox[3];

        const {x: adjStartX, y: adjStartY} = frameCoordToCanvas(
          start_x, start_y
        );
        const {x: adjEndX, y: adjEndY} = frameCoordToCanvas(
          end_x, end_y
        );
        arr.push({
          gId,
          x1: adjStartX,
          y1: adjStartY,
          x2: adjEndX,
          y2: adjEndY
        });
      }
    }

    this.cattleBoxes = arr;
  }

  handleMouseMove(e) {
    if (this.mouseDown) {
      this.handleScroll(e);
      return;
    }

    const hitbox = this.findHitBox(e);
    if (!hitbox) {
      this.streamer.canvas.style.cursor = 'auto';
    } else {
      this.streamer.canvas.style.cursor = 'pointer';
    }

    const gId = hitbox?.gId || null;

    this.streamer.hoverId = gId;
  }

  handleScroll(e) {
    if (!this.mouseDown) {
      return;
    }

    this.mouseMove = true;

    const deltaX = e.clientX - this.mousePos.x;
    const deltaY = e.clientY - this.mousePos.y;

    this.pan(deltaX, deltaY);

    this.mousePos = { x: e.clientX, y: e.clientY };
  }

  handleMouseDown(e) {
    this.mouseDown = true;
    this.mousePos = { x: e.clientX, y: e.clientY };
  }

  handleMouseUp(e) {
    const click = !this.mouseMove;
    if (click) {
      this.handleClick(e)
    }

    this.mouseDown = false;
    this.mouseMove = false;
    this.mousePos = null;
  }

  handleClick(e) {
    if (!this.streamer) return;

    const hitbox = this.findHitBox(e);

    if (!hitbox) {
      this.streamer.onClick(null);
      return;
    }

    this.streamer.onClick(hitbox.gId || '012823');
  }

  isFullscreen() {
    return (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    );
  }

  enterFullscreen() {
    if (this.streamer.container.requestFullscreen) {
      this.streamer.container.requestFullscreen();
    } else if (this.streamer.container.webkitRequestFullscreen) {
      /* Safari */
      this.streamer.container.webkitRequestFullscreen();
    } else if (this.streamer.container.msRequestFullscreen) {
      /* IE11 */
      this.streamer.container.msRequestFullscreen();
    }
  }

  exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }

  toggleFullscreen() {
    if (this.isFullscreen()) {
      this.exitFullscreen();
    } else {
      this.enterFullscreen();
    }
  }

  onKeyDown(e) {
    switch (e.key) {
      case 'a':
        this.panLeft();
        break;
      case 'ArrowLeft':
        this.panLeft();
        break;
      case 'd':
        this.panRight();
        break;
      case 'ArrowRight':
        this.panRight();
        break;
      case 'w':
        this.panUp();
        break;
      case 'ArrowUp':
        this.panUp();
        break;
      case 's':
        this.panDown();
        break;
      case 'ArrowDown':
        this.panDown();
        break;
      case 'c':
        this.center();
        break;
      case 'f':
        this.toggleFullscreen();
        break;
      case '+':
        this.zoomIn();
        break;
      case '_':
        this.zoomOut();
        break;
      default:
        // do nothing
    }
  }

  transform(zoom, offsetX, offsetY) {
    this.streamer.videoSrc.style.transform = `translate(${offsetX}px, ${offsetY}px) scale(${zoom})`;
  }

  updateView(zoom, offsetX, offsetY) {
    zoom = zoom ?? this.zoom;
    offsetX = offsetX ?? this.offsetX;
    offsetY = offsetY ?? this.offsetY;

    const { offsetMin, offsetMax } = this.offsetBounds(zoom);

    let newOffsetX = Math.min(offsetX, offsetMax.x);
    newOffsetX = Math.max(newOffsetX, offsetMin.x);
    let newOffsetY = Math.min(offsetY, offsetMax.y);
    newOffsetY = Math.max(newOffsetY, offsetMin.y);

    const newZoom = Math.max(zoom, this.minZoom);

    this.offsetX = newOffsetX;
    this.offsetY = newOffsetY;
    this.zoom = newZoom;

    this.transform(newZoom, newOffsetX, newOffsetY);
  }

  zoomIn() {
    this.streamer.trackActiveId = false;
    this.updateView(this.zoom * 1.3333333333);
  }

  zoomOut() {
    this.streamer.trackActiveId = false;
    this.updateView(this.zoom * 0.75);
  }

  pan(deltaX, deltaY) {
    this.streamer.trackActiveId = false;
    this.updateView(this.zoom, this.offsetX + deltaX, this.offsetY + deltaY);
  }

  panUp() {
    this.streamer.trackActiveId = false;
    this.updateView(this.zoom, this.offsetX, this.offsetY + 20);
  }

  panDown() {
    this.streamer.trackActiveId = false;
    this.updateView(this.zoom, this.offsetX, this.offsetY - 20);
  }

  panLeft() {
    this.streamer.trackActiveId = false;
    this.updateView(this.zoom, this.offsetX + 20);
  }

  panRight() {
    this.streamer.trackActiveId = false;
    this.updateView(this.zoom, this.offsetX - 20);
  }

  center() {
    this.streamer.trackActiveId = false;
    this.updateView(1, 0, 0);
  }

  adjustScreen(payload) {
    if (!this.streamer.trackActiveId || !payload) {
      return;
    }

    const trackingBox = payload.instances.find(pose => pose.gId?.toString() === this.streamer.activeId);
    if (!trackingBox) {
      return;
    }

    const { bbox } = trackingBox;

    let start_x = bbox[0];
    let start_y = bbox[1];
    let end_x = bbox[2];
    let end_y = bbox[3];

    const { frameWidth, frameHeight } = payload;

    const boxWidth = end_x - start_x;
    const boxHeight = end_y - start_y;
    const percentWidth = frameWidth / boxWidth;
    const percentHeight = frameHeight / boxHeight;
    const minPercent = Math.min(percentWidth, percentHeight);

    const percentPadding = 0.45; // don't zoom in all the way
    const percentZoom = minPercent * percentPadding;

    const frameCoordToCanvasFn = this.streamer.frameCoordToCanvas({ payload }, percentZoom, 0, 0);

    const offsetX = ((Math.max(boxWidth, boxHeight) / percentPadding) - boxWidth) / 2;
    const offsetY = ((Math.max(boxWidth, boxHeight) / percentPadding) - boxHeight) / 2;

    const { x, y } = frameCoordToCanvasFn(
      start_x - offsetX,
      start_y - offsetY
    );

    this.updateView(percentZoom, -x, -y);
  }

  start() {
    this.listeners.forEach((listener) => {
      const { event, fn } = listener;
      this.streamer.canvas.addEventListener(event, fn);
    });

    window.addEventListener('resize', this.center.bind(this));
  }

  stop() {
    this.listeners.forEach((listener) => {
      const { event, fn } = listener;
      this.streamer.canvas.removeEventListener(event, fn);
    });

    window.removeEventListener('resize', this.center.bind(this));
  }

  offsetBounds(zoom) {
    zoom = Math.max(zoom, 1);

    const {
      clientWidth,
      clientHeight
    } = this.streamer.canvas;

    const {
      clientWidth: videoWidth,
      clientHeight: videoHeight
    } = this.streamer.videoSrc;

    const canvasWidth = zoom * videoWidth;
    const canvasHeight = zoom * videoHeight;

    let x = (canvasWidth - clientWidth) / 2;
    let y = (canvasHeight - clientHeight) / 2;

    if (canvasWidth <= clientWidth) {
      x = 0;
    }

    if (canvasHeight <= clientHeight) {
      y = 0;
    }

    return {
      offsetMin: { x: -x, y: -y },
      offsetMax: { x, y },
    };
  }
}

export default EventHandler;
