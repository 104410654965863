import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo
} from 'react';

import css from './styles.module.scss';

import Viewer from '../../../components/Viewer';

import ExpandTable from '../ExpandTable';

// const targetAspectRatio = 1;

function CameraViewGrid(props) {
  const {
    devices = [],
    socketClient,
    updateCattleId,
    showIds,
    highlightId,
    shouldDrawMovement,
    shouldDrawHeadPos,
    isDebugView,
    isLoading,
    selectedId,
    areaCattle,
    highlights
  } = props;

  const gridRef = useRef();
  const viewersRef = useRef({});

  const [numberOfRows, setNumberOfRows] = useState(1);
  const [showTable, setShowTable] = useState(false);

  const calcGrid = useCallback(() => {
    const { clientWidth, clientHeight } = gridRef.current;

    const numberOfSquares = devices.length;

    let rows = 1;
    for (let i = 2; i <= numberOfSquares; i += 1) {
      const prevWidth = clientWidth / Math.ceil(numberOfSquares / rows);
      const nextWidth = clientWidth / Math.ceil(numberOfSquares / i);

      const prevHeight = clientHeight / rows;
      const nextHeight = clientHeight / i;

      // const prevRatio = prevWidth / prevHeight;
      // const nextRatio = nextWidth / nextHeight;

      const prevCalcArea = Math.min(prevWidth, prevHeight) ** 2;
      const nextCalcArea = Math.min(nextWidth, nextHeight) ** 2;

      const hasBetterArea = nextCalcArea > prevCalcArea;
      if (!hasBetterArea) {
        break;
      }

      rows = i;
    }

    setNumberOfRows(rows);
  }, [devices]);

  const cameraGrid = useMemo(() => {
    const grid = [];
    for (let i = 0; i < numberOfRows; i += 1) {
      grid.push([])
    }

    devices.forEach((d, i) => {
      const rowIndex = i % numberOfRows;
      grid[rowIndex].push(d)
    });
    
    return grid;
  }, [numberOfRows, devices]);

  useEffect(() => {
    calcGrid();

    window.addEventListener('resize', calcGrid);

    return () => {
      window.removeEventListener('resize', calcGrid);
    };
  }, [calcGrid]);

  const expandBtn = (
    <button
      onClick={() => setShowTable(!showTable)}
      disabled={isLoading}
      className={css.btnExpand}
      style={{
        backgroundColor: showTable ? '#3099FD' : '#fff',
        color: showTable ? '#fff' : '#3099FD',
      }}
    >
      <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.1536 5.10769H5.16458C4.69712 5.10769 4.31818 5.58983 4.31818 6.18462C4.31818 6.7794 4.69712 7.26154 5.16458 7.26154H18.1536C18.6211 7.26154 19 6.7794 19 6.18462C19 5.58983 18.6211 5.10769 18.1536 5.10769Z" fill={showTable ? '#fff' : '#3099FD'} />
        <path d="M18.1536 0H5.16458C4.69712 0 4.31818 0.482139 4.31818 1.07692C4.31818 1.67171 4.69712 2.15385 5.16458 2.15385H18.1536C18.6211 2.15385 19 1.67171 19 1.07692C19 0.482139 18.6211 0 18.1536 0Z" fill={showTable ? '#fff' : '#3099FD'} />
        <path d="M18.1536 9.84615H5.16458C4.69712 9.84615 4.31818 10.3283 4.31818 10.9231C4.31818 11.5179 4.69712 12 5.16458 12H18.1536C18.6211 12 19 11.5179 19 10.9231C19 10.3283 18.6211 9.84615 18.1536 9.84615Z" fill={showTable ? '#fff' : '#3099FD'} />
        <path d="M1.07955 2.15385C1.67576 2.15385 2.15909 1.67169 2.15909 1.07692C2.15909 0.482155 1.67576 0 1.07955 0C0.483329 0 0 0.482155 0 1.07692C0 1.67169 0.483329 2.15385 1.07955 2.15385Z" fill={showTable ? '#fff' : '#3099FD'} />
        <path d="M1.07955 7.26154C1.67576 7.26154 2.15909 6.77938 2.15909 6.18462C2.15909 5.58985 1.67576 5.10769 1.07955 5.10769C0.483329 5.10769 0 5.58985 0 6.18462C0 6.77938 0.483329 7.26154 1.07955 7.26154Z" fill={showTable ? '#fff' : '#3099FD'} />
        <path d="M1.07955 12C1.67576 12 2.15909 11.5178 2.15909 10.9231C2.15909 10.3283 1.67576 9.84615 1.07955 9.84615C0.483329 9.84615 0 10.3283 0 10.9231C0 11.5178 0.483329 12 1.07955 12Z" fill={showTable ? '#fff' : '#3099FD'} />
      </svg>
      Expand Table
    </button>
  );

  const blockEls = cameraGrid.map((row, i, grid) => {
    const height = (1 / grid.length) * 100;

    const rowBlocks = row.map((device, di) => {
      const label = `${device.name}_${Date.now()}`;

      const snapshotBtn = isDebugView
        ? (
          <button
            className="btn btn-primary"
            onClick={() => {
              viewersRef.current[device.id]?.snapshot(label);
              viewersRef.current[device.id]?.snapshot(label, true);
            }}
          >
            Snapshot
          </button>
        )
        : null;

      return (
        <div
          key={device.id}
          className={css.column}
          style={{ width: 100 / row.length }}
        >
          <Viewer
            ref={el => viewersRef.current[device.id] = el}
            // coverStyle="cover"
            coverStyle="contain"
            deviceId={device.id}
            deviceIP={device.device_ip}
            isJSmpeg={device.is_rtsp}
            className={css.cameraContainer}
            liveViewMode={device.liveViewMode}
            customElsRight={snapshotBtn}
            onClick={updateCattleId}
            showIds={showIds}
            highlightId={highlightId}
            shouldDrawMovement={shouldDrawMovement}
            shouldDrawHeadPos={shouldDrawHeadPos}
            isDebugView={isDebugView}
            socketClient={socketClient}
            highlights={highlights}
          />
          <label className={css.cameraTitle}>{device.name}</label>
        </div>
      );
    });

    return (
      <div
        key={i}
        className={css.row}
        style={{ height: `${height}%` }}
      >
        {rowBlocks}
      </div>
    )
  });

  return (
    <div
      ref={gridRef}
      className={css.liveViewContainer}
    >
      {blockEls}
      {expandBtn}
      <ExpandTable
        show={showTable}
        setShow={setShowTable}
        selected={selectedId}
        setSelected={updateCattleId}
        data={areaCattle}
        isDebugView={isDebugView}
        isLoading={isLoading}
      />
    </div>
  );
}

export default CameraViewGrid;
