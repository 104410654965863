import React from 'react';

import css from './styles.module.scss';

function HeaderCheckbox(props) {
  const { label, val, checked, onCheck } = props;

  return (
    <div className={css.box}>
      <input
        type="checkbox"
        checked={checked}
        onChange={e => onCheck(e.target.checked)}
      />
      <div className={css.info}>
        <label>{label}</label>
        <span>{val}</span>
      </div>
    </div>
  );
}

export default HeaderCheckbox;
