import React, { useContext } from 'react';
import GoogleMapReact from 'google-map-react';
import { useNavigate } from 'react-router-dom';

import CameraStackContext from '../../../context/CameraStackContext';

import PenListView from './PenListView';

import AreaMarker from './AreaMarker';
import DeviceMarker from './DeviceMarker';

import css from '../styles.module.scss';

const { REACT_APP_GOOGLE_MAP_KEY } = process.env;

const options = {
  mapTypeId: 'satellite',
  zoomControl: true,
  mapTypeControl: true,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: true,
  fullscreenControl: false
};

function PoleMap(props) {
  const { locationId, data, isFetching, performance } = props;

  const { addToStack } = useContext(CameraStackContext);

  const navigate = useNavigate();

  function selectArea(area) {
    const device = area?.devices[0];
    addToStack(area.id, device.id, device.name);

    let url = `/producers/herd-sense?locationId=${locationId}&monitoringAreaId=${area.id}&deviceId=${device.id}`;
    if (performance.length) {
      const filters = performance.join(',');
      url += `&filters=${filters}`;
    }

    navigate(url);
  }

  function selectDevice(areaId, device) {
    addToStack(areaId, device.id, device.name);

    let url = `/producers/herd-sense?locationId=${locationId}&monitoringAreaId=${areaId}&deviceId=${device.id}`;
    if (performance.length) {
      const filters = performance.join(',');
      url += `&filters=${filters}`;
    }

    navigate(url);
  }

  function onMapsLoaded({ map, maps }) {
    const bounds = new maps.LatLngBounds();
    data?.forEach((area) => {
      area.devices.forEach((device) => {
        bounds.extend({
          lat: device.latitude,
          lng: device.longitude
        });
      });
    });

    map.fitBounds(bounds);
  }

  if (isFetching) {
    return null;
  }

  const areaMarkers = data
    .map((area) => {
      const latitude = area.devices.length
        ? area.devices.reduce((s, d) => s + d.latitude, 0) / area.devices.length
        : null;

      const longitude = area.devices.length
        ? area.devices.reduce((s, d) => s + d.longitude, 0) / area.devices.length
        : null;

      const count = performance.reduce((sum, key) => sum + area[key], 0);

      return (
        <AreaMarker
          key={area.id}
          lat={latitude}
          lng={longitude}
          label={area.name}
          onClick={() => selectArea(area)}
          count={count}
        />
      );
    });

  const deviceMarkers = data
    ?.reduce((arr, area) => {
      arr.push(...area.devices);
      return arr;
    }, [])
    .map((device) => (
      <DeviceMarker
        key={device.id}
        online={device.online}
        lat={device.latitude}
        lng={device.longitude}
        label={device.name}
        onClick={() => selectDevice(device.monitoring_area_id, device)}
      />
    ));

  return (
    <div className={css.mapView}>
      <PenListView
        locationId={locationId}
        data={data}
        addToStack={addToStack}
        performance={performance}
      />
      <div className={css.mapContainer}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: `${REACT_APP_GOOGLE_MAP_KEY}&loading=async` }}
          onGoogleApiLoaded={onMapsLoaded}
          yesIWantToUseGoogleMapApiInternals
          defaultCenter={{ lat: 0, lng: 0 }}
          defaultZoom={15}
          options={options}
        >
          {deviceMarkers}
          {areaMarkers}
        </GoogleMapReact>
      </div>
    </div>
  );
}

export default PoleMap;
