import React from 'react';
import moment from 'moment'

import css from './styles.module.css';

function BunkGraph(props) {
  const { data, header } = props;

  const bunkBlocks = data?.data?.pctFull.length
    ? data.data.pctFull.map((perc, i) => {
      let color = 'red';
      if (perc > 0.33) {
        color = 'orange';
      }

      if (perc > 0.66) {
        color = 'green';
      }

      return (
        <div key={i} className={css.bunkBlock}>
          <div
            className={css.bunkBlockPerc}
            style={{ flexGrow: perc, backgroundColor: color }}
          />
          <div className={css.bunkBlockOverlay}>
            <span>{(perc * 100).toFixed(0)}%</span>
          </div>
        </div>
      );
    })
    : <span>No bunk data</span>;

  const createdAt = data?.created_at
    ? moment.utc(data?.created_at).local().format('L h:mm a')
    : '';

  return (
    <div>
      <div className={css.headerContainer}>
        <h3>{header}</h3>
        <span>
          {createdAt}
        </span>
      </div>
    	<div className={css.container}>
        {bunkBlocks}
      </div>
    </div>
  );
}

export default BunkGraph;
