import React from 'react';

import IconNoVideo from '../../../icons/icon-no-video';
import IconVideoRecorder from '../../../icons/icon-video-recorder';

import css from '../styles.module.scss';

function DeviceMarker(props) {
	const { online, onClick } = props;

	const iconEl = online ? <IconVideoRecorder /> : <IconNoVideo />;

	return (
		<div
			onClick={onClick}
			className={css.marker}
		>
			{iconEl}
		</div>
	);
}

export default DeviceMarker;
