import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import Loader from '../../components/Loader';
import CattleDetailsNote from '../../components/CattleDetailsNote';
import CustomSlider from '../../components/forms/CustomSlider';

import HealthNoteModal from './HealthNoteModal';
import PenCattleGraph from './PenCattleGraph';

import css from './styles.module.scss';

function Details(props) {
  const {
    cattleId,
    locationId,
    showHeader,
    areaId,
    mode = 'light',
    data,
    device,
    refetch,
    isLoading,
    highlightId,
    setHighlightId,
    timezone
  } = props;

  const [showHealthNoteModal, setShowHealthNoteModal] = useState(false);
  const [healthNoteType, setHealthNoteType] = useState(null);

  const detailId = (data?.name || cattleId).toString();

  const yesterday = moment().tz(timezone).startOf('day').subtract(1, 'days');
  const fifteenDaysAgo = moment().tz(timezone).startOf('day').subtract(15, 'days');

  useEffect(() => {
    if (highlightId && highlightId !== detailId) {
      setHighlightId(null);
    }
  }, [cattleId, detailId, highlightId, setHighlightId]);

  function addWatchListNote() {
    setShowHealthNoteModal(true);
    setHealthNoteType('watch');
  }

  function addPullListNote() {
    setShowHealthNoteModal(true);
    setHealthNoteType('pull');
  }

  function addVetListNote() {
    setShowHealthNoteModal(true);
    setHealthNoteType('vet');
  }

  const historyEls = data?.health_notes
    ? data?.health_notes
      .sort((a, b) => a.created_at < b.created_at ? 1 : -1)
      .map((note) => (
        <CattleDetailsNote key={note.id} note={note} className={css.note} />
      ))
    : 'No notes found';

  // const mugImage = data?.mug_url
  //   ? <img src={data.mug_url} style={{ maxHeight: '200px' }} alt='' />
  //   : null;

  const firstSeen = data
    ? moment.utc(data?.created_at).tz(timezone).format('M/D/YY hh:mm A')
    : 'N/A';
  const lastSeen = data
    ? moment.utc(data?.updated_at).tz(timezone).format('M/D/YY hh:mm A')
    : 'N/A';

  const weightData = (data?.metrics || []).map((m) => ({
    ...m,
    data: m.weight.toFixed(0)
  }));

  const weightAvg = data?.metrics?.length
    ? data.metrics.reduce((s, m) => s + m.weight, 0)
      / data.metrics.length
    : null;

  const walkKeys = ['run', 'trot', 'walk'];
  const idleKeys = ['eat', 'idle', 'drink', 'laying', 'urinate'];
  const movementKeys = ['idle', 'moving'];
  const intakeKeys = ['eat', 'drink'];

  // const movementData = Object.values(
  //   (data?.metrics || [])
  //     .map((dayData) => {
  //       const walkTotal = walkKeys.reduce((sum, key) => sum + dayData.movement[key], 0);
  //       const idleTotal = idleKeys.reduce((sum, key) => sum + dayData.movement[key], 0);

  //       return {
  //         created_at: dayData.created_at,
  //         moving: walkTotal,
  //         idle: idleTotal
  //       }
  //     })
  //     .reduce((map, m) => {
  //       movementKeys.forEach((key) => {
  //         map[key].push({
  //           created_at: m.created_at,
  //           data: (m[key] * 100).toFixed(0)
  //         });
  //       });

  //       return map;
  //     }, {
  //       idle: [],
  //       moving: [],
  //     })
  // );

  const intakeData = Object.values(
    (data?.metrics || [])
      .map((dayData) => {
        const intakeTotal = intakeKeys.reduce((sum, key) => sum + dayData.movement[key], 0);

        return {
          created_at: dayData.created_at,
          data: (intakeTotal * 24).toFixed(2)
        }
      })
  );

  return (
    <>
      <Loader loading={isLoading}>
        <div className={`${css.content} ${css.contentCattle}`}>
          <div
            className={css.imgBlock}
            style={{ color: 'black' }}
          >
            {/*{mugImage}*/}

            <CustomSlider
              name="live-view"
              label="Live View Animal"
              value={!!highlightId}
              onChange={e => setHighlightId(e.target.checked ? detailId : null)}
            />
          </div>

          <div className={css.seenBlock}>
            <div className={css.item}>
              <h4 className={css.label}>First Seen</h4>
              <span className={css.entry}>{firstSeen}</span>
            </div>
            <div className={css.item}>
              <h4 className={css.label}>Last Seen</h4>
              <span className={css.entry}>{lastSeen}</span>
            </div>
          </div>

          {/*<div
            className={css.item}
            style={{ marginTop: 10 }}
          >
            <div className={css.labelBlock}>
              <div className={css.label}>Gait Graph</div>
              <div className={css.entryAlt}>All Time</div>
            </div>
            <div
              className={css.plcBox}
              style={{ backgroundColor: mode !== 'dark' ? '#f6f6f6' : '#fff' }}
            />
          </div>*/}

          {/*<PenCattleGraph
            header="Movement"
            timeLabel="Last two weeks"
            valueKey="data"
            data={movementData}
            graphLabel={movementKeys.map(k => `${k.charAt(0).toUpperCase()}${k.slice(1)} %`)}
            multiple
            barColor={['rgb(48 153 253 / 75%)', 'rgb(48 153 253 / 50%)']}
            startDate={fifteenDaysAgo}
            endDate={yesterday}
          />*/}

          <PenCattleGraph
            header="Weight Performance"
            timeLabel="Last two weeks"
            graphLabel="lbs"
            valueKey="data"
            avg={weightAvg}
            data={weightData}
            startDate={fifteenDaysAgo}
            endDate={yesterday}
          />

          <PenCattleGraph
            header="Lameness Indicator"
            timeLabel="Last two weeks"
            graphLabel="category"
            valueKey="data"
            data={[]}
            startDate={fifteenDaysAgo}
            endDate={yesterday}
            min={0}
            max={3}
            lameness
          />
          
          <PenCattleGraph
            header="Intake"
            timeLabel="Last two weeks"
            valueKey="data"
            data={intakeData}
            graphLabel="hours"
            barColor={['rgb(48 153 253 / 75%)', 'rgb(48 153 253 / 50%)']}
            startDate={fifteenDaysAgo}
            endDate={yesterday}
          />

          <div
            className={css.item}
            style={{ marginTop: 10 }}
          >
            <h3 className={css.label}>Animal History</h3>
            <div className={css.box}>
              {historyEls}
            </div>
          </div>
        </div>
        <div className={css.actions}>
          <button
            className={`btn btn-outline-primary`}
            onClick={() => addWatchListNote(cattleId)}
            disabled={isLoading}
            style={{ gap: 0, justifyContent: 'center' }}
          >
            Watch List
          </button>
          <button
            className={`btn btn-outline-primary`}
            onClick={() => addPullListNote(cattleId)}
            disabled={isLoading}
            style={{ justifyContent: 'center' }}
          >
            Pull List
          </button>
          {/*<button
            className={`btn btn-danger ${css.danger}`}
            onClick={() => addVetListNote(cattleId)}
            disabled={isLoading}
          >
            Vet Check
          </button>*/}
        </div>
      </Loader>

      <HealthNoteModal
        showModal={showHealthNoteModal}
        setShowModal={setShowHealthNoteModal}
        areaId={areaId}
        locationId={locationId}
        refetch={refetch}
        type={healthNoteType}
        cattleId={detailId}
      />
    </>
  );
}

export default Details;
